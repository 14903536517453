import { SETTING } from "../app-config/cofiguration";
import Axios from "axios";
import { ToWords } from 'to-words';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { timeNewReg, timeNewBold, timeNewItalic, timeNewBoldItalic,corbenBold, corbenRegular, abrilFatfaceRegular,writingRegTxt} from '../util/font'
const CryptoJS = require('crypto-js');
const os = require('os');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["Times-New-Roman-Regular.ttf"] = timeNewReg;
pdfMake.vfs["Times-New-Roman-Bold.ttf"] = timeNewBold;
pdfMake.vfs["Times-New-Roman-Italics.ttf"] = timeNewItalic;
pdfMake.vfs["Times-New-Roman-Bold-Italics.ttf"] = timeNewBoldItalic;
pdfMake.vfs['Corben-Regular.ttf'] =corbenRegular
pdfMake.vfs['Corben-Bold.ttf']= corbenBold
pdfMake.vfs['AbrilFatface-Regular.ttf']= abrilFatfaceRegular
pdfMake.vfs['GreatVibes-Regular.ttf']= writingRegTxt
const SECRET_MSG =  process.env.REACT_APP_SECRET_MSG
const USER = localStorage.getItem("userInformation") && JSON.parse(localStorage.getItem("userInformation"));
export const activityTypeList =['Login', 'Logout', 'Update','Create/Add','Delete','Menu Log','Event Log','Status Change','Suspended company', 'Error Log']

const getIpAdress= ()=>{
  fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      const ipAddress = data.ip;
     return ipAddress
    })
    .catch(error => {
      console.error('ipAddress Error:', error);
      return 'N/A'
    });
}
const getDevice=()=>{
  const platform = os.platform();

  console.log('Platform:', platform);

  // Get the operating system type (e.g., 'Windows_NT', 'Linux', 'Darwin')
  const osType = os.type();
  console.log('Operating System Type:', osType);

  // Get the operating system release version (e.g., '10.0.19043', '5.4.0-84-generic', '20.6.0')
  const release = os.release();
  console.log('Release Version:', release);

  // Get the CPU architecture (e.g., 'x64', 'arm64')
  const arch = os.arch();
  console.log('CPU Architecture:', arch);

  // Get information about the available CPUs
  const cpus = os.cpus();
  console.log('CPU Information:', cpus);

  // Get the total memory (in bytes) on the system
  const totalMemory = os.totalmem();
  console.log('Total Memory:', totalMemory, 'bytes');

  // Get the free memory (in bytes) on the system
  const freeMemory = os.freemem();
  console.log('Free Memory:', freeMemory, 'bytes');

  // Get the hostname of the system
  const hostname = os.hostname();
  console.log('Hostname:', hostname);

  return release

}
const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: { // can be used to override defaults for the selected locale
      name: 'Rupee',
      plural: 'Rupees',
      symbol: '₹',
      fractionalUnit: {
        name: 'Paisa',
        plural: 'Paise',
        symbol: '',
      },
    }
  }
});

export const currentSession =()=>{
  const currentDate= new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth= currentDate.getMonth()
  let session=''
  if(currentMonth>=3){
      session = `${(currentYear).toString()}-${(currentYear+1).toString().substring(2)}`
  }else if(currentMonth<3 ){
      session = `${(currentYear-1).toString()}-${(currentYear).toString().substring(2)}`
  }
  //console.log("session", session)
  return session
}


export const logoutFunc=(errData)=>{
    if(errData.response && errData.response.status===401){
        localStorage.clear()
        window.location.href = '/login'
    }
    // else if(!errData || !errData.response || !errData.response.data || !errData.response.data.message){
    //     window.location.href = '/login'
    // }
}

export const capitalize=(sentance)=>{ 
    sentance = sentance.replace(/ +(?= )/g,'')
     if(sentance.length===0) return ""
    const words = sentance.split(" "); 
    const capitalizeWord = words.map((word) => { 
        if(word.trim().length>0) return word[0].toUpperCase() + (word.substring(1)? word.substring(1).trim().length>0 ? word.substring(1).trim().toLowerCase():'':''); 
    }).join(" "); 
    return capitalizeWord
 }

 export const encryptAES = (text) => {
    return CryptoJS.AES.encrypt(text, SECRET_MSG).toString();
  }

  export const convertMilliSecToHrMints=(milliseconds=0)=> {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedHours = ("0" + hours).slice(-2); // Ensures a leading zero if needed
    const formattedMinutes = ("0" + minutes).slice(-2); // Ensures a leading zero if needed
    return formattedHours + ":" + formattedMinutes;
}

export const groupedData =(data, key) => {
    console.log("data", key)
    data.reduce((acc, obj) => {
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export const saveSecurityLogs= async(menuUrl, activityType, message='',userId='')=>{
  let dataToSend={
    message: message,
    menuUrl: menuUrl,
    activity_type: activityType, 
    userId: USER && USER._id?USER._id:userId?userId:'' , 
    // ipAdress: getIpAdress(),
    // device: getDevice()
  }

  let options = SETTING.HEADER_PARAMETERS;
  options['Authorization'] = localStorage.getItem("token")
  await Axios.post(SETTING.APP_CONSTANT.API_URL+`security/save`,dataToSend,{headers: options})
  .then((res) => {
     // console.log("resss", res)
  })
  .catch((err) =>{
    console.log("errror", err)
  });

}
export const  generateRandomID=() =>{
  const randomNum = Math.floor(Math.random() * 1000000);
  // Convert the number to a string and pad it with leading zeros if necessary
  const randomID = String(randomNum).padStart(6, '0');

  return randomID;
}

export const  unitOption =[
  {value:'KGS',label:'KGS'},
]




export const generateGSTInvoice=(data)=>{
  console.log("datattatttttt", data)

  // console.log("data.detaildata.detail",        data.detail.map((data, index)=>{
  //   return[
  //     `${data.index+1}`,`${data.particulars}`, `${data.amount}`
  //   ]
  //   })
  // )
  const dateFormat=(date)=>{
    const newDate = new Date(date)
    const yyyy = newDate.getFullYear()
    let mm = newDate.getMonth() + 1 // Months start at 0!
    let dd = newDate.getDate()
    
    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    
    return dd + '/' + mm + '/' + yyyy
  }


  pdfMake.fonts = { 
    // Default font should still be available
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
    TimesNewRoman: {
        normal: 'Times-New-Roman-Regular.ttf',
        bold: 'Times-New-Roman-Bold.ttf',
        italics: 'Times-New-Roman-Italics.ttf',
        bolditalics: 'Times-New-Roman-Bold-Italics.ttf'
    },
    Corben: {
      normal: 'Corben-Regular.ttf',
      bold: 'Corben-Bold.ttf',
  }
}
  
  var docDefinition = {
    // background: function (page) {
    //     return [
    //       {
    //         image: principalSign,
    //         width: 40,
    //         height: 40,
    //         absolutePosition: { x: 460, y: 347 }
    //       },
    //       {
    //         image: examController2,
    //         width: 60,
    //         height: 40,
    //         absolutePosition: { x: 276, y: 347 }
    //       },
    //       {
    //         image: principalSign,
    //         width: 40,
    //         height: 40,
    //         absolutePosition: { x: 460, y: 737 }
    //       },
    //       {
    //         image: examController2,
    //         width: 60,
    //         height: 40,
    //         absolutePosition: { x: 276, y: 737 }
    //       },
    //     ];
    // },
    content: 
      [
      {
        stack: [
          { text:'Bill of Supply', style: 'subheaderAdd'},
        ],
        style: 'header'
      },

      {
        //style: 'tableExample',
        //color: '#444',
        fontSize:9,
        table: {
          widths: ['50%', 122, 122],
          //headerRows: 2,
          // keepWithHeaderRows: 1,
          body: [
            [
              {
                rowSpan:3, 
                  stack:[
                    { text: data.sellTrader.traderName? data.sellTrader.traderName:'', alignment: 'left', bold: true, margin:[0,0,0,0] },
                    { text: data.sellTrader.address1?  data.sellTrader.address1:'', alignment: 'left', margin:[0,0,0,0] },
                    { text: `${data.sellTrader.address2? `${data.sellTrader.address2} - `:''}${data.sellTrader.zipCode? data.sellTrader.zipCode:''}`, alignment: 'left', margin:[0,0,0,0] },
                    { text: `GSTIN/UIN    : ${data.sellTrader.GSTNumber}`, alignment: 'left', margin:[0,0,0,0] },
                    { text: `State Name      : ${data.sellTrader.state}, Code  :  ${data.sellTrader.code? data.sellTrader.code:''}`, alignment: 'left', margin:[0,0,0,0] }
                  ]
              
              },
              {
                stack:[
                  { text: 'Invoice No', alignment: 'left', margin:[0,0,0,0] },
                  { text: data.invoiceNo, alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }, 
              {
                stack:[
                  { text: 'Dated', alignment: 'left', margin:[0,0,0,0] },
                  { text: dateFormat(data.created), alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }
            ],
            ['', 
              {
                stack:[
                  { text: 'Delivery Note', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              },
              {
                stack:[
                  { text: 'Mode/Terms of Payment', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }
            ],
            ['',   
              {
                stack:[
                  { text: 'Supplier\'s Ref.', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              },
              {
                stack:[
                { text: 'Other Reference(s)', alignment: 'left', margin:[0,0,0,0] },
                { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }
            ],
            [
              {
                rowSpan:5, 
                  stack:[
                    { text: 'Buyer', alignment: 'left', margin:[0,0,0,0] },
                    { text:  data.buyerInfo.traderName||'', alignment: 'left', bold: true, margin:[0,0,0,0] },
                    { text: data.buyerInfo.address1||'', alignment: 'left', margin:[0,0,0,0] },
                    { text: `${data.buyerInfo.address2? `${data.buyerInfo.address2} - `:''}${data.buyerInfo.zipCode||''}`, alignment: 'left', margin:[0,0,0,0] },
                    { text: `GSTIN/UIN    : ${data.buyerInfo.GSTNumber||''}`, alignment: 'left', margin:[0,0,0,0] },
                    { text: `State Name      : ${data.buyerInfo.state||''}, Code  :  ${data.buyerInfo.code||''}`, alignment: 'left', margin:[0,0,0,0] }
                  ]
              
              },
              {
                stack:[
                  { text: 'Buyer\'s Order No.', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              },
              {
                stack:[
                  { text: 'Dated', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }
            ],
            ['',    
              {
                stack:[
                  { text: 'Dispatch Document No.', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              },
              {
                stack:[
                  { text: 'Delivery Note Date', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }
            ],
            ['', 
              {
                stack:[
                  { text: 'Dispatch Through', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              },
              {
                stack:[
                  { text: 'Destination', alignment: 'left', margin:[0,0,0,0] },
                  { text: data.destAdd, alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }
            ],
            ['',    
              {
                stack:[
                  { text: 'Bill of Landing/LR-RR No.', alignment: 'left', margin:[0,0,0,0] },
                  { text: ' ', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              },
              {
                stack:[
                  { text: 'Motor Vehicle No.', alignment: 'left', margin:[0,0,0,0] },
                  { text: data.vehicleNumber||'', alignment: 'left', margin:[0,0,0,0] , bold:true},
                ]
              }
            ],
            ['', {colSpan: 2, text: 'Terms of Delivery\n  \n\n\n'}],
            //['Sample value 1', '', ''],
          ]
        }
      },
      {
        fontSize:12,
        table: {
          //heights: 30,
          widths: [12, 225, 46, 60, 30, 25, 54],
          body: [
              [
                { text: 'Sl\nNo', style: 'tableHeader', alignment: 'center', margin:[0,0,0,0], fontSize:8 },
                { text: 'Description of Goods', style: 'tableHeader', alignment: 'center', margin:[0,0,0,0] },
                { text: 'HSN/SAC', style: 'tableHeader', alignment: 'center', margin:[0,0,0,0] },
                { text: 'Quantity', style: 'tableHeader', alignment: 'center', margin:[0,0,0,0], fontSize:10 },
                { text: 'Rate', style: 'tableHeader', alignment: 'center', margin:[0,0,0,0] },
                { text: 'Per', style: 'tableHeader', alignment: 'center', margin:[0,0,0,0] },
                { text: 'Amount', style: 'tableHeader', alignment: 'center', margin:[0,0,0,0] },
               
              ]
          ]
        },
      },
      {
        fontSize:12,
        table: {
          heights: 250,
          widths: [12, 225, 46, 60, 30, 25, 54],
          body: [
            [
              {  
                  stack:    
                  [
                    data.detail.map((data, index)=>{
                      return{ 
                          text: `${data.index?data.index:' '}`,
                          style: 'tableHeader',
                          alignment: 'center', 
                          margin:[0,0,0,0] 
                        }
                    })
                  ]                              
              },
              {  
                stack:    [
                   data.detail.map((data, index)=>{
                    if(data.particulars){
                      return{ 
                        text: `${data.particulars}`,
                        style: 'tableHeader',
                        alignment: 'left', 
                        margin:[0,0,0,0] 
                      }
                    }else{
                      return{ 
                        text: `${data.description? data.description:' '}`,
                        style: 'tableHeader',
                        alignment: 'left',
                        italics:true, 
                        margin:[4,0,0,0] 
                      }
                    }
                  })
                ]                              
              },
              {  
                stack:    [
                  data.detail.map((data, index)=>{
                    return{ 
                        text: `${data.hsn? data.hsn:' '}`,
                        style: 'tableHeader',
                        alignment: 'right', 
                        margin:[0,0,0,0] 
                      }
                  })
                ]                              
              },
              {  
                stack:    [
                  data.detail.map((data, index)=>{
                    return{ 
                        text: `${data.qty? Number(data.qty).toFixed(2):' '}`,
                        style: 'tableHeader',
                        alignment: 'right', 
                        margin:[0,0,0,0] 
                      }
                  })
                ]                              
              },
              {  
                stack:    [
                  data.detail.map((data, index)=>{
                    return{ 
                        text: `${data.rate? Number(data.rate).toFixed(2):' '}`,
                        style: 'tableHeader',
                        alignment: 'right', 
                        margin:[0,0,0,0] 
                      }
                  })
                ]                              
              },
              {  
                stack:    [
                  data.detail.map((data, index)=>{
                    return{ 
                        text: `${data.per? data.per:' '}`,
                        style: 'tableHeader',
                        alignment: 'right', 
                        margin:[0,0,0,0] 
                      }
                  })
                ]                              
              },
              {  
                stack:    [
                  data.detail.map((data, index)=>{
                    return{ 
                        text: `${data.amount? data.amount:' '}`,
                        style: 'tableHeader',
                        alignment: 'right', 
                        margin:[0,0,0,0] 
                      }
                  })
                ]                              
              },
            ],
          ]
        },
      },
      {
        fontSize:9,
        table: {
          //heights: 40,
          widths: [12, 225, 46, 60, 30, 25, 54],
          body: [
            [
              { text: ' ', 
                alignment: 'left', margin:[0,0,0,0] , bold: false 
              }, 
              { text: 'Total',  
                alignment: 'right', margin:[0,0,0,2] , bold: false 
              },   
              { text: ' ', 
                alignment: 'left', margin:[0,0,0,0] , bold: false 
              },
              { text: `${Number(data.totalWeight).toFixed(2)}`, 
                alignment: 'right', margin:[0,0,0,0] , bold: false 
              },
              { text: ' ', 
                alignment: 'left', margin:[0,0,0,0] , bold: false 
              },  
              { text: ' ', 
                alignment: 'left', margin:[0,0,0,0] , bold: false 
              }, 
              {
                //colSpan: 2,
                text: `${Number(data.totalAmount).toFixed(2)}`,  
                alignment: 'right', margin:[0,0,0,0] , bold: false 
              }, 
            ],
            [ 
              { 
                border: [true, true, false, false],
                colSpan: 5,
                 text: 'Amount chargeable (in words)'
              },  
              {},
              {},
              {},
              {},
              { 
                border: [false, true, true, false],
                colSpan: 2,
                alignment: 'right',
                text: 'E & O E',
                italics:true
              },
            ],
            [ 
              {        //left, up, right, bottom
                border: [true, false, true, true],
                colSpan: 7,
                text: `INR ${toWords.convert(Number(data.totalAmount).toFixed(2))}`,
                bold: true
              },  
              {},
              {},
              {},
              {},
              {},
              {}
            ],
            [ 
              { 
                colSpan: 6,
                alignment: 'center',
                 text: 'HSN/SAC'
              },  
              {},
              {},
              {},
              {},
              {},
              { 
                alignment: 'center',
                text: 'Taxable\nValue',
                //italics:true
              },
            ],
            [ 
              { 
                colSpan: 6,
                //alignment: 'center',
                 text: data.sellInfo[0].hsn
              },  
              {},
              {},
              {},
              {},
              {},
              { 
                alignment: 'center',
                text: Number(data.totalAmount).toFixed(2),
                //italics:true
              },
            ],
            [ 
              { 
                colSpan: 6,
                alignment: 'right',
                 text: 'Total',
                 bold: true
              },  
              {},
              {},
              {},
              {},
              {},
              { 
                alignment: 'center',
                text: Number(data.totalAmount).toFixed(2),
                bold:true
              },
            ],
            [ 
              { 
                border: [true, true, true, false],
                colSpan: 7,
                rowSpan:4,
                text: ['Tax Amount (In words)  :',{ text: 'NILL', bold: true }]
              },
              '',  
              '',
              '',
              '',
              '',
              ''
            ],
            [ 
              '',
              '',  
              '',
              '',
              '',
              '',
              ''
            ],
            [ 
              '',
              '',  
              '',
              '',
              '',
              '',
              ''
            ],
            [ 
              '',
              '',  
              '',
              '',
              '',
              '',
              ''
            ],
            [ 
              {         //left, up, right, bottom
                border: [true, false, false, false],
                colSpan: 2,
                text: ' '
              },  
              {},
              {         //left, up, right, bottom
                border: [false, false, true, true],
                colSpan: 5,
                
                  stack: [
                    {
                      text: ['Company\'s Bank Details '],
                    },
                    {
                      text: ['Bank Name                  : ',{ text: data.payInfo.bankName||'', bold: true }],
                    },
                    {
                      text: ['A/c No.                         : ',{ text: data.payInfo.accountNo||'', bold: true }]
                    },
                    {
                      text: ['Branch & IFSC Code  : ',{ text: `${data.payInfo.branchName||''}${data.payInfo.ifscCode? ` & ${data.payInfo.ifscCode||''}`:''}`, bold: true }],
                    }
                  ],
              },
              {},
              {},
              {},
              {}
            ],
            [ 
              {         //left, up, right, bottom
                border: [true, false, true, true],
                colSpan: 2,
                stack: [
                  {
                    text:'Declaration',
                    bold: true,
                    decoration:'underline'
                  },
                  {
                    text:'We declare that Invoice shows the actual price of the goods described and that all particulars are true and correct.'
                  }
                ]
              },  
              {},
              {         //left, up, right, bottom
                border: [true, true, true, true],
                colSpan: 5,
                
                  stack: [
                    {
                      text: `for ${data.sellTrader.traderName||''}` ,
                      bold: true,
                      alignment: 'right',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' '
                    },
                    {
                      text: 'Authorised Signatory',
                      alignment: 'right',
                    }
                  ],
              },
              {},
              {},
              {},
              {}
            ],
          ]
        },
      },
      // {
      //   canvas: [
      //       {
      //         type: 'rect',
      //         x: 0,
      //         y: 0,
      //         w: 0,
      //         h: 10,
      //         //r: 5,
      //         //dash: { length: 5 },
      //         // lineWidth: 10,
      //         lineColor: 'blue',
      //       },
      //     ]
      // },
      //table End
      {
        text: 'This is a computer generated invoice.',
        //bold: true
        fontSize: 9,
        alignment: 'center'
      },

    ],
    defaultStyle: {
      font: 'TimesNewRoman'
    },
    // to make library image
    // images: {
    //   logo: schoolLogo
    // },
    styles: {
      header: {
        fontSize: 22,
        bold: false,
        alignment: 'center',
        margin: [0, 5, 0, 0]
      },
      subheaderTrust: {
        fontSize: 10,
        bold: false
      },
      subheaderAdd: {
        fontSize: 10,
        bold: false
      },
      subheaderExam: {
        fontSize: 14,
        decoration:'underline'
      },
      subheaderSession: {
        fontSize: 12,
        decoration:'underline'
      },
      logoEstdAndTrust: {
        fontSize: 7,
      },
      stInfo: {
        fontSize: 12,
        alignment: 'left',
        margin: [0, 2, 0, 0]
      },
     
      tableHeader: {
        //bold: true,
        fontSize: 10,
        color: 'black',
      },
      marksAttendenceInfo:{
        fontSize: 12,
        alignment: 'left',
        margin: [0, 5, 0, 0]
      }
      // superMargin: {
      //   margin: [20, 0, 40, 0],
      //   fontSize: 15
      // }
    }
  };
      const localhost = window.location.host.includes("localhost")
      const pdfName= `${data.buyerInfo.traderName}_${data.invoiceNo}.pdf`
      if(localhost){
        pdfMake.createPdf(docDefinition).open();
      }else{
        pdfMake.createPdf(docDefinition).download(pdfName);
      }
}



